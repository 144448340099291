@use "./theme/theme";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


h2{
  font-weight: 500;
  text-wrap: pretty;
}

.spinner {
  height: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
