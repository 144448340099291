@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme, $hue) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $warn-palette: map.get($color-config, "warn");
  //$background: map.get($theme, background);
  //$background-color: map-get($background, background);

  .drawer-container {
    &__options {
      mat-list-item {
        mat-icon {
          color: rgba(
            $color: mat.get-color-from-palette($warn-palette, $hue),
            $alpha: 0.6
          ) !important;
        }
        span {
          color: mat.get-color-from-palette($warn-palette, $hue);
        }
      }
    }
    .drawer-container__menu-item--active {
      background-color: mat.get-color-from-palette($primary-palette, $hue);

      mat-icon {
        color: mat.get-contrast-color-from-palette($primary-palette, $hue);
      }
      span {
        color: mat.get-contrast-color-from-palette($primary-palette, $hue);
      }
    }

    .drawer-container__menu-item--active:hover {
      background-color: mat.get-color-from-palette($primary-palette, $hue);

      mat-icon {
        color: mat.get-contrast-color-from-palette($primary-palette, $hue);
      }
      span {
        color: mat.get-contrast-color-from-palette($primary-palette, $hue);
      }
    }
  }
}
