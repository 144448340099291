@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme, $hue) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");

  $background: map.get($theme, foreground);
  $background-color: map-get($background, base);

  $primary: mat.get-color-from-palette($primary-palette, $hue);
  $accent: mat.get-color-from-palette($accent-palette, $hue);
  $warn: mat.get-color-from-palette($warn-palette, $hue);

  .custom-chip {
    background-color: rgba($color: $background-color, $alpha: 0.2);
    color: rgba($color: $background-color, $alpha: 0.8);

    &--primary {
      background-color: rgba($color: $primary, $alpha: 0.2);
      color: rgba($color: $primary, $alpha: 0.8);
    }

    &--accent {
      background-color: rgba($color: $accent, $alpha: 0.2);
      color: rgba($color: $accent, $alpha: 0.8);
    }

    &--warn {
      background-color: rgba($color: $warn, $alpha: 0.2);
      color: rgba($color: $warn, $alpha: 0.8);
    }
  }
}
