@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme, $hue) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $warn-palette: map.get($color-config, "warn");
  $background: map.get($theme, background);
  $background-color: map-get($background, background);

  $icon: map.get($theme, foreground);
  $icon-color: map-get($icon, text);
  .auth-section {
    background-color: $background-color;
  }

  mat-form-field {
    mat-icon {
      color: $icon-color;
    }
  }
}
